
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import userPreferenceDB from '@/lib/userPreference';


@Component
export default class ChartLegendComponent extends Vue {
  @Prop({ type: Array, required: false, default: [] }) legendSignals?: any;
  @Prop({ type: String, required: false, default: ()=> [] }) type?: any;
  @Prop({ type: Boolean, required: false, default: true }) isWellSignal?: any;
  @Prop({ type: Boolean, required: false, default: true }) useAddSignalFeature?: any;

  handleClick(index, name) {
    this.$emit('select-signal-on-legend', { name, index, type: this.type });

  }

  legendSelectedSignals: any = []


  async created(){

    const res: any = await userPreferenceDB.getItem(`${this.type}Selected`)
    if(res && res.length > 0){
      this.legendSelectedSignals = res
    }

  }

  get sortedSignals() {
    console.log('checking from here');
    console.log(this.useAddSignalFeature);

    if(this.legendSelectedSignals.length == 0 || !this.useAddSignalFeature){
      console.log('this is comming from here');
      console.log(this.legendSignals);
      return  this.legendSignals.sort((a, b) => a.name.localeCompare(b.name));
    }
    return this.legendSignals.filter((signal) => this.legendSelectedSignals.includes(signal.name.toLowerCase())).sort((a, b) => a.name.localeCompare(b.name));
  }



  @Watch('legendSignals')
  async onLegendSignalsChange() {
    const res: any = await userPreferenceDB.getItem(`${this.type}Selected`)
    if(res && res.length > 0){

      this.legendSelectedSignals = res
    }
  }



  getCurrentSignalNameForLegend(signal_name) {
    if (signal_name == 'gas_rate') {
      return 'Gas Rate';
    }
    if (signal_name == 'oil_rate') {
      return 'Oil Rate';
    }
    if (signal_name == 'water_rate') {
      return 'Water Rate';
    }
    if (signal_name == 'boe_rate') {
      return 'Boe Rate';
    }
    if (signal_name == 'water_rate_fc') {
      return 'Water Rate FC';
    }
    if (signal_name == 'water_rate_source') {
      return 'Water Rate Source';
    }
    if (signal_name == 'gas_rate_fc') {
      return 'Gas Rate FC';
    }
    if (signal_name == 'oil_rate_fc') {
      return 'Oil Rate FC';
    }
    if (signal_name == 'gas_rate_source') {
      return 'Gas Rate Source';
    }
    if (signal_name == 'oil_rate_source') {
      return 'Oil Rate Source';
    }
    return signal_name;
  }
}
